<template>
  <div style="display: contents">
    <v-row>
      <v-col cols="6" class="half-image-login"> </v-col>
      <v-col
        cols="12"
        xl="6"
        lg="6"
        md="6"
        sm="12"
        xs="12"
        class="half-form-login"
      >
        <v-row class="box">
          <v-col cols="12" class="mt-0 pt-0 pb-2">
            <img src="../../assets/img/okan_4.png" class="logo-login" />
            <h1>BIENVENIDO A OKAN</h1>
            <p class="sub-title mb-0">
              🎇 Comienza tus grandes interacciones ❤
            </p>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <v-form ref="form" class="form-login">
              <v-row justify="center">
                <v-col
                  cols="12"
                  xl="8"
                  lg="8"
                  md="8"
                  sm="8"
                  xs="12"
                  class="pb-0 px-0 mx-0"
                >
                  <div class="label-login">
                    <h2>Correo Electrónico</h2>
                  </div>
                  <v-text-field
                    class="form-login-textfield ml-0 ml-xl-0 ml-lg-0 ml-md-5 ml-sm-0"
                    :rules="[
                      (v) => !!v || 'El correo electrónico es requerido.',
                    ]"
                    regular
                    dark
                    placeholder="Ingrese aquí el correo electrónico"
                    prepend-inner-icon="mdi-account-circle-outline"
                    v-model="form.email"
                  >
                  </v-text-field>
                  <div class="label-login">
                    <h2>Cédula</h2>
                  </div>
                  <v-text-field
                    class="form-login-textfield ml-0 ml-xl-0 ml-lg-0 ml-md-5 ml-sm-0"
                    :rules="[(v) => !!v || 'La cédula es requerida.']"
                    regular
                    dark
                    v-model="form.cedula"
                    placeholder="Ingrese aquí la cédula"
                  >
                  </v-text-field>
                  <v-btn
                    class="form-login-btn mt-3 mr-5 ml-0 ml-xl-3 ml-lg-3 ml-md-3 ml-sm-0 mb-5"
                    block
                    rounded
                    color=""
                    :loading="loadingAll"
                    @click="initUser()"
                  >
                    <v-img
                      src="https://d1b1fjiwh8olf2.cloudfront.net/icon/premium/png-256/1763082.png?token=eyJhbGciOiJoczI1NiIsImtpZCI6ImRlZmF1bHQifQ__.eyJpc3MiOiJkMWIxZmppd2g4b2xmMi5jbG91ZGZyb250Lm5ldCIsImV4cCI6MTY1NDkwNTYwMCwicSI6bnVsbCwiaWF0IjoxNjU0NzAwMjI3fQ__.0109ccd283be63e251e16b82ad2fe206e2dc06bd5c022dc1a30101fe39b7f808"
                      max-height="30"
                      max-width="30"
                    >
                    </v-img>
                    ACCEDER
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <Alert
      :open="alert.open"
      :text="alert.txt"
      :title="alert.title"
      :type="alert.type"
      @close="alert.open = false"
    >
    </Alert>
  </div>
</template>
<script>
// import swal from "@/mixins/swal";
import router from "@/router";
import Alert from "../../components/Alert.vue";
import Api from "@/utils/api";
export default {
  name: "Login",
  components: {
    Alert,
  },
  data() {
    return {
      alert: {
        txt: "",
        title: "",
        type: "",
        open: false,
      },
      open: false,
      directory: false,
      modal: {
        sms: false,
      },
      title: "",
      sms: "",
      loadingAll: false,
      errors: {
        user: false,
        pass: false,
      },
      form: {
        email: "",
        cedula: "",
      },
      show: false,
      hidden: false,
    };
  },
  watch: {},
  methods: {
    initUser() {
      this.loadingAll = true;
      var data = new FormData();
      data.append("email", this.form.email);
      data.append("cedula", this.form.cedula);
      Api.newUser()
        .loginNewUser(data)
        .then((data) => {
          let dataUser = {
            email: this.form.email,
            cedula: this.form.cedula,
            data: data.data.data,
          };
          router.push({
            name: "PasosIniciales",
            params: { userData: dataUser },
          });
        })
        .catch((error) => {
          this.alert.open = true;
          this.alert.title = "¡Oh no!";
          this.alert.txt = "Hubo un error vuelve a intentar";
          this.alert.type = "error";
          this.loadingAll = false;
        });
    },
  },
};
</script>
<style scoped>
.col-img {
  width: 100%;
  height: 0;
  position: absolute;
}

.sombrero-login {
  width: 30%;
  top: -147px;
  left: 20px;
  position: relative;
}

.div-logo {
  width: 40px;
  height: 24px;
  margin: 10px;
  background-color: #fff;
}

.v-btn {
  text-transform: none !important;
}

.login-text {
  text-align: center;
}

.half-image-login {
  background-image: url(../../assets/img/login-half.png);
  background-size: cover;
  z-index: 5;
}
.half-form-login {
  background: linear-gradient(315deg, #141b33 20%, #2e3e75 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-size {
  max-width: initial;
}

.box {
  max-width: 90%;
  border-radius: 10px;
  backdrop-filter: blur(1px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding-bottom: 10px;
  margin: 30px !important;
}

.logo-login {
  /* width: 700px;
  height: 300px; */
  filter: drop-shadow(2px 4px 6px black);
  width: 230px;
  height: 230px;
}

.label-login {
  font-family: "AsapRegular" !important;
  text-align: left;
  padding-bottom: 0px;
}

h1 {
  font-family: "AsapRegular" !important;
  color: #f2f4ff;
  font-weight: 700;
  font-size: 40px;
  line-height: 57px;
}

h2 {
  font-family: "AsapRegular" !important;
  color: #f2f4ff;
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
}

.sub-title {
  text-shadow: 2px 1px 3px black;
  color: #ffffff;
  font-weight: 300;
  font-size: 22px;
  line-height: 37px;
  font-size: "ProximaNovaRegular";
}

.form-login-textfield {
  padding-bottom: 10px;
}

.form-login-btn {
  background: linear-gradient(179.5deg, #466be3 5%, #324ca1 100%);
  box-shadow: 0px 2px 8px rgba(50, 76, 161, 0.5),
    0px 4px 24px rgba(50, 76, 161, 0.3);
  color: #fff;
  border-radius: 20px;
  font-weight: bold;
  height: 8.5%;
  font-size: 13px;
}

.form-login-olvide {
  font-style: normal;
  font-size: 15px;
  color: #889de2;
  margin-top: 20px;
  text-decoration: underline;
  font-weight: bold;
}

.form-login-google {
  color: #c72b36;
  font-weight: bold;
  margin-top: 20px;
  height: 8.5%;
  font-size: 13px;
}

.login-text-tooltip {
  font-family: "AsapRegular";
  font-weight: 600;
  font-size: 16px;
  color: #353535;
}

.btn-visibility {
  visibility: visible;
}

.btn-hidden {
  visibility: hidden;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
  .sombrero-login {
    width: 50%;
    top: -147px;
    left: 20px;
    position: relative;
  }
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
  .sombrero-login {
    width: 70%;
    top: -130px;
    left: 20px;
    position: relative;
  }
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .sombrero-login {
    width: 70%;
    top: -100px;
    left: 17px;
    position: relative;
  }

  .half-image-login {
    display: none;
  }

  .sub-title {
    font-size: 30px;
    font-weight: bold;
    font-family: "AsapRegular" !important;
  }

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }

  .logo-login {
    width: 150px;
    height: 150px;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .sombrero-login {
    width: 70%;
    top: -100px;
    left: 17px;
    position: relative;
  }

  .box {
    max-width: 100%;
  }

  .half-image-login {
    display: none;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 44px;
  }

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }

  .sub-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    font-family: "AsapRegular" !important;
  }

  .logo-login {
    width: 150px;
    height: 150px;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  /* .sombrero-login {
    width: 38%;
    top: -100px;
    left: 17px;
    position: relative;
  } */
  .half-image-login {
    display: none;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 44px;
  }

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }

  .sub-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    font-family: "AsapRegular" !important;
    margin-bottom: 0px;
  }

  .logo-login {
    width: 150px;
    height: 150px;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .sombrero-login {
    width: 80%;
    top: -90px;
    left: 13px;
    position: relative;
  }

  .half-image-login {
    display: none;
  }

  .form-login-btn {
    font-size: 10px;
  }

  .form-login-google {
    font-size: 10px;
  }

  .form-login-olvide {
    font-size: 11px;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 44px;
  }

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }

  .sub-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    font-family: "AsapRegular" !important;
    margin-bottom: 0px;
  }

  .logo-login {
    width: 150px;
    height: 150px;
  }
}
</style>
